import { Link } from 'gatsby'
import styled from 'styled-components'
import React from 'react'

const Nav = () => (
  <StyledNav>
    <FlexContainer>
      <li>
        <StyledNavLink data-testid="nav-item" to="/about">
          About Fivelde
        </StyledNavLink>
      </li>
      <li>
        <StyledNavLink data-testid="nav-item" to="/">
          Home
        </StyledNavLink>
      </li>
    </FlexContainer>
  </StyledNav>
)

export default Nav

const StyledNav = styled.header`
  background-color: ${props => props.theme.colors.green};
  box-sizing: border-box;
  color: white;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
`

const FlexContainer = styled.ul`
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  max-width: 1200px;
  list-style: none;
  padding: 0;
`

const StyledNavLink = styled(Link)`
  flex-grow: 2;
  font-size: 18px;
  font-weight: 300;
  padding: 0 8px;
`
