import React from 'react'
import styled from 'styled-components'

const VerticalSeparator = () => {
  return <StyledSeparator />
}

export default VerticalSeparator

const StyledSeparator = styled.div`
  display: inline-block;
  width: 8px;
`
