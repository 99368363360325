const theme = {
  colors: {
    darkGreen: 'rgb(42, 120, 50)',
    green: 'rgb(70, 146, 74)',
    black: 'rgb(37, 37, 37)',
  },
  breaks: {
    medium: '800px'
  },
  margins: {
    headerHeight: '66px'
  }
}

export default theme
