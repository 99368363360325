import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../Header/header'
import theme from '../../theme'

import './layout.css'
import Nav from '../Nav/nav'

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Header siteTitle={data.site.siteMetadata.title} />
          <PageContent data-testid="layout-content">{children}</PageContent>
          <Nav />
        </>
      )}
    />
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const PageContent = styled.div`
  margin-top: ${props =>
    props.theme.margins.headerHeight}; // add header height as a variable
  margin-bottom: ${props =>
    props.theme.margins.headerHeight}; // add footer height as a variable
  color: ${props => props.theme.colors.black};
`
