import { Link } from 'gatsby'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'

import Logo from '../../images/leaf-small.png'

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <FlexContainer>
      <Link data-testid="link-logo" to="/">
        <Image src={Logo} />
      </Link>
      <StyledHeadingLink data-testid="link-title" to="/">
        {siteTitle}
      </StyledHeadingLink>
      <StyledParagraph>Eco friendly materials</StyledParagraph>
    </FlexContainer>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const StyledHeader = styled.header`
  background-color: ${props => props.theme.colors.green};
  box-sizing: border-box;
  color: white;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`

const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
`

const StyledHeadingLink = styled(Link)`
  flex-grow: 2;
  font-size: 20px;
  font-weight: 500;
  padding: 0 8px;
`

const StyledParagraph = styled.p`
  margin: 0;
`

const Image = styled.img`
  height: 30px;
  width: 30px;
`
