import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { FaCheckCircle } from 'react-icons/fa'

import Button from '../Button/button'
import { VerticalSeparator } from '../Separators'

const ListingItem = ({ listing }) => {
  return (
    <ItemContainer>
          <Heading>
            <Link to={`/listing/${listing.contentful_id}/`}>
              {listing.name}&nbsp;
              {listing.approved && (
                <span>
                  <FaCheckCircle />
                </span>
              )}
            </Link>
          </Heading>
        <Description>
          {listing.description.description.substring(0, 240)}...
        </Description>
        <Tags>
          {listing.tags.map(item => (
            <TagItem key={item}>{item}</TagItem>
          ))}
        </Tags>
        <ButtonContainer>
          <Link to={`/listing/${listing.contentful_id}/`}>
            <Button>more info</Button>
          </Link>
          <VerticalSeparator />
            <a href={listing.website} target="_blank" rel="noopener noreferrer">
              <Button>visit site</Button>
            </a>
        </ButtonContainer>
      </ItemContainer>
  )
}

export default ListingItem

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 10px 0;
  
  @media (min-width: ${props => props.theme.breaks.medium}) {
    padding: 0;
    margin-bottom: 20px;
  }
`

const Heading = styled.h1`
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 1;
  margin-bottom: 5px;

  @media (min-width: ${props => props.theme.breaks.medium}) {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  span {
    color: ${props => props.theme.colors.darkGreen};
    vertical-align: middle;
  }
`
const Description = styled.p`
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 3;
  grid-row-end: 4;
  margin-bottom: 10px;

  @media (min-width: ${props => props.theme.breaks.medium}) {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 3;
  }
`

const Tags = styled.ul`
  display: flex;
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 4;
  grid-row-end: 5;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin-bottom: 10px;

  @media (min-width: ${props => props.theme.breaks.medium}) {
    display: flex;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
  }
`

const TagItem = styled.li`
  background-color: ${props => props.theme.colors.darkGreen};
  border-radius: 8px;
  color: white;
  font-size: 13px;
  margin: 0 5px 5px 0;
  padding: 4px;
`

const ButtonContainer = styled.div`
  align-self: center;
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 5;
  grid-row-end: 5;

  @media (min-width: ${props => props.theme.breaks.medium}) {
    grid-column-start: 6;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 2;
    justify-self: end;
  }
`
