import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import ListingItem from '../components/ListingItem/listingItem'
import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'

const IndexPage = ({ data }) => {
  const { edges } = data.allContentfulListing

  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[
          `fivelde`,
          `eco`,
          `edirectory`,
          `renewable`,
          `ethical`,
          `directory`,
          `building`,
          `fairtrade`,
          `supplies`,
        ]}
      />
      <Container>
        {edges.map(({ node }) => (
          <ListingItem key={node.id} listing={node} />
        ))}
      </Container>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allContentfulListing {
      edges {
        node {
          id
          contentful_id
          name
          tags
          approved
          rating
          description {
            description
          }
          website
        }
      }
    }
  }
`

const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 0 10px;
`
