import React from 'react'
import styled from 'styled-components'

const Button = ({ children }) => {
  return (
    <StyledButton>
      <p data-testid="button-text">{children}</p>
    </StyledButton>
  )
}

export default Button

const StyledButton = styled.button`
  background-color: ${props => props.theme.colors.darkGreen};
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-bottom: 5px;
  outline: none;
  padding: 10px;
  text-transform: capitalize;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme.colors.green};
  }
`
